import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import { fetchSession } from "../../../../auth";

const columns = [
  {
    id: "m_organization",
    label: "送信先",
    minWidth: "120px",
    isSmallScreen: true,
  },
  {
    id: "users",
    label: "送信予定件数",
    minWidth: "120px",
    operation: true,
    isSmallScreen: false,
  },
];

const operationVaridate = (input) =>
  input ? (
    <IconButton color="primary" size="small">
      <LaunchIcon sx={{ width: "20px" }} />
    </IconButton>
  ) : null;

const PreviewTable = (props) => {
  const { roleOrOrganization } = props;
  // const roleOrOrganization = {m_role_id:null,m_organization_id:null}
  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // const [popoverData, setPopoverData] = useState(null);

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  //権限情報取得
  const [previews, setPreviews] = useState(null);
  const [totalCount, setTotalCount] = useState();

  const getRoles = async () => {
    const tokenInfo = await fetchSession();
    let apiUrl = '';
    let param = {};
    if (roleOrOrganization.m_role_id && roleOrOrganization.m_role_id.length > 0) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/mail/roles`;
      param = { m_role_id: roleOrOrganization.m_role_id };
    } else if (roleOrOrganization.m_organization_id && roleOrOrganization.m_organization_id.length > 0) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/mail/organizations`;
      param = { m_organization_id: roleOrOrganization.m_organization_id };
    }
    const params = {
      params: param,
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("プレビュー取得入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = roleOrOrganization.m_role_id
          ? response.data.m_roles
          : response.data.m_organizations;
        const totalCount = response.data.total_count;
        const status = response.data;
        console.log("プレビュー取得", response);
        setPreviews(data);
        setTotalCount(totalCount);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    if (
      roleOrOrganization.m_role_id !== null ||
      roleOrOrganization.m_organization_id !== null
    ) {
      getRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleOrOrganization.m_role_id, roleOrOrganization.m_organization_id]);

  //検索結果ページネーション
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      borderBottom: 2,
                      padding: "8px",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
              {/* {row{}e(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
              {previews?.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => handleLocationCodeClick(row)}
                    sx={{
                      pointerEvents: "none",
                      "@media screen and (max-width:900px)": {
                        cursor: "pointer",
                        pointerEvents: "auto",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            padding: "8px",
                            "@media screen and (max-width:900px)": {
                              display:
                                column.isSmallScreen === true
                                  ? "table-cell"
                                  : "none",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {column.id === "users" ? (
                              <>{row.users.length}件</>
                            ) : column.id === "m_organization" ? (
                                <>{row.m_organization.department_name}</>
                            ) : (
                              <>{value}</>
                            )}

                            {column.operation ? operationVaridate(value) : null}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              被害履歴詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {selectedRow &&
                columns.slice(0, 4).map((column) => {
                  const value = selectedRow[column.id];
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                      <TableCell
                        align={column.align}
                        sx={{
                          color: "outline.dark",
                          fontWeight: "bold",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            WebkitWritingMode: "horizontal-tb",
                            height: "100%",
                          }}
                        >
                          {column.label}
                        </Box>
                      </TableCell>
                      <TableCell
                        align={column.align}
                        sx={{
                          writingMode: "horizontal-tb",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                          overflowWrap: "break-word",
                        }}
                      >
                        {column.id === "users" ? (
                          <>{selectedRow.users.length}件</>
                        ) : column.id === "m_organization" ? (
                          <>{selectedRow.m_organization.department_name}</>
                        ) : (
                          <>{value}</>
                        )}
                        {column.operation ? operationVaridate(value) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Dialog>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalCount | 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
              margin: "0 !important",
              padding: "0 !important",
            },
          }}
        />
        <SnackbarCustom
          data={snackBarstatus}
          openStatus={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
      </Paper>
    </>
  );
};
export default PreviewTable;
