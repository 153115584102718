import * as React from "react";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const columns = [
  {
    id: "importdate",
    label: "インポート日時",
    minWidth: "fit-content",
    isSmallScreen: true,
  },
  {
    id: "status",
    label: "ステータス",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: true,
  },
  {
    id: "complete",
    label: "完了日時",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "message",
    label: "メッセージ",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
  },
];

const rows = [
  createData(
    "2023/01/12 11:15",
    "正常完了",
    "2023/01/12 11:18",
    "12335件を正常にインポートしました。",
    1
  ),
  createData(
    "2023/01/12 11:15",
    "正常完了",
    "2023/01/12 11:18",
    "12335件を正常にインポートしました。",
    2
  ),
  createData(
    "2023/01/12 11:15",
    "正常完了",
    "2023/01/12 11:18",
    "12335件を正常にインポートしました。",
    3
  ),
  createData(
    "2023/01/12 11:15",
    "正常完了",
    "2023/01/12 11:18",
    "12335件を正常にインポートしました。",
    4
  ),
];

function createData(importdate, status, complete, message, dataId) {
  return {
    importdate,
    status,
    complete,
    message,
    dataId,
  };
}

export default function ImportLog() {
  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      padding: "8px",
                      fontWeight: "bold",
                      borderBottom: 2,
                      borderBottomColor: "outline.dark",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.dataId}
                      onClick={() => handleLocationCodeClick(row)}
                      sx={{
                        pointerEvents: "none",
                        "@media screen and (max-width:900px)": {
                          cursor: "pointer",
                          pointerEvents: "auto",
                        },
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              padding: "8px",
                              "@media screen and (max-width:900px)": {
                                display:
                                  column.isSmallScreen === true
                                    ? "table-cell"
                                    : "none",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "32px",
                              }}
                            >
                              <>{value}</>
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              アカウント詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {selectedRow &&
                columns.map((column) => {
                  const value = selectedRow[column.id];
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                      <TableCell
                        align={column.align}
                        sx={{
                          color: "outline.dark",
                          fontWeight: "bold",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            WebkitWritingMode: "horizontal-tb",
                            height: "100%",
                          }}
                        >
                          {column.label}
                        </Box>
                      </TableCell>
                      <TableCell
                        align={column.align}
                        sx={{
                          writingMode: "horizontal-tb",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                          overflowWrap: "break-word",
                        }}
                      >
                        <>{value}</>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Dialog>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          status={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
              margin: "0 !important",
              padding: "0 !important",
            },
          }}
        />
      </Paper>
    </>
  );
}
