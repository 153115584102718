import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  TextField,
  Dialog,
  IconButton,
  Divider,
} from "@mui/material";
import {
  Close as CloseIcon,
  Download as DownloadIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import SnackbarCustom from "../../components/SnackBarCustom";
import PopoverCustom from "../../components/PopoverCustom";
import { formatDate } from "../../utils/deteUtils";
import { fetchSession } from "../../auth";

const columns = [
  {
    id: "display_code",
    label: "Sコード",
    minWidth: "112px",
    bg: true,
    isSmallScreen: true,
  },
  {
    id: "name",
    label: "SS名称",
    minWidth: "112px",
    bg: true,
    isSmallScreen: true,
  },
  { id: "relation_code", label: "納入先コード", minWidth: "120px" },
  {
    id: "name",
    label: "支店",
    minWidth: "120px",
    align: "left",
    isSmallScreen: true,
    m_branch: true,
  },
  {
    id: "sales_staff",
    label: "担当者名",
    minWidth: "184px",
    align: "left",
    isSmallScreen: false,
    textField: true,
  },
  {
    id: "name",
    label: "得意先名",
    minWidth: "120px",
    align: "left",
    isSmallScreen: false,
    m_customer: true,
  },
  {
    id: "operator",
    label: "運営店",
    minWidth: "120px",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "zip",
    label: "郵便番号",
    minWidth: "96px",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "address",
    label: "住所",
    minWidth: "240px",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "owned_kind_name",
    label: "所有区分",
    minWidth: "96px",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "compe_kind_name",
    label: "運営区分",
    minWidth: "96px",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "tel",
    label: "電話番号",
    minWidth: "120px",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "holiday",
    label: "定休日",
    minWidth: "240px",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "update_at",
    label: "更新日時",
    minWidth: "240px",
    align: "left",
    isSmallScreen: false,
  },
];

const OperatorTextfield = (props) => {
  const { siteData, changedValue } = props;
  const handleChange = (e) => {
    if (typeof changedValue === "function") {
      changedValue(e.target.value);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "8px" }}>
      <TextField
        size="small"
        variant="outlined"
        value={siteData?.sales_staff || ""}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ pointerEvents: "auto" }}
      />
    </Box>
  );
};

const SearchResult = (props) => {
  const { searchContent } = props;

  //SS基本台帳インポート画面へ遷移
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  //詳細ポップアップ管理
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleLocationCodeClick = (row, rowNum) => {
    setSelectedRow(row);
    setSelectedRowNum(rowNum);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };
  //検索結果ページネーション
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // 災害対象SS一覧取得
  const [sites, setSites] = useState([]);
  const [totalData, setTotalData] = useState();

  const getSites = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/sites`;
    const params = {
      params: {
        display_code: searchContent["display_code"],
        name: searchContent["name"],
        pref: searchContent["pref"],
        // pref: [1,2],
        m_branch_id: searchContent["m_branch_id"], // 例: 都道府県コードを指定 (複数指定可能)
        m_customer_id: searchContent["m_customer_id"], // 例: 得意先IDを指定 (複数指定可能)
        latest_alive: searchContent["latest_alive"],
        latest_sales: searchContent["latest_sales"],
        latest_authori: searchContent["latest_authori"],
        owned_kind: searchContent["owned_kind"],
        // owned_kind: [1,2],
        // owned_kind: 3,
        order: null, // デフォルト値: 'code'
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.sites;
        const total = response.data.total_count;
        const status = response.data;
        // テナント情報をステートにセット
        setSites(data);
        setTotalData(total);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //初回起動時の読み込み回避
  const isFirstRender = useRef(false);
  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false; // もう初回レンダーじゃないよ代入
    } else {
      getSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  //担当者名管理
  const handleChangeOperator = (inputValue, siteId) => {
    const updatedArray = [...sites]; // オリジナルの配列をコピー
    //sites配列内からidが当てはまる配列を探す
    const updatedItem = updatedArray.find((item) => item.id === siteId);
    if (updatedItem) {
      updatedItem.sales_staff = inputValue;
      setSites(updatedArray);
    }
  };

  //登録ボタン選択行管理
  const [selectedRowNum, setSelectedRowNum] = useState("");

  //担当者名登録ポップオーバー管理
  const [confirmPopoverOpen, setPopoverSplitOpen] = useState(false);
  const putPostButtonClick = (e, rowNum) => {
    e.stopPropagation();
    setSelectedRowNum(rowNum);
    setPopoverSplitOpen(true);
  };
  const confirmPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };

  //担当者名登録
  const putOperator = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    // const apiUrl = `http://localhost:8080/site/${sites[selectedRowNum].id}`;
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/site/${sites[selectedRowNum].id}`;
    const params = {
      key: "sales_staff",
      sales_staff: sites[selectedRowNum].sales_staff,
      // sir_start_at: "2024/01/17",
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    if (isSuccess) {
      axios
        .put(apiUrl, params, headers)
        .then((response) => {
          // レスポンスのデータを取得
          const status = response.data;
          //スナックバーステート
          setSnackBarstatus(status);
          setSnackbarOpen(true);
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
        })
    }
  };

  const ButtonVaridate = (props) => {
    const { rowNum } = props;
    return (
      <IconButton
        color="primary"
        sx={{ pointerEvents: "auto" }}
        onClick={(e) => putPostButtonClick(e, rowNum)}
      >
        <SaveIcon />
      </IconButton>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          padding: "16px 0 32px",
          "@media screen and (max-width:600px)": {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Button variant="contained" onClick={getSites} sx={{ width: "208px" }}>
          検索
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleClick("/ssinfo/import")}
          sx={{ width: "208px" }}
        >
          <DownloadIcon />
          SS基本台帳インポート
        </Button>
      </Box>
      <Divider />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0px",
              },
            }}
          >
            検索結果
          </Box>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <TableCell
                      key={column.label}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{
                        padding: "8px",
                        fontWeight: "bold",
                        borderBottom: 2,
                        borderBottomColor: "outline.dark",
                        backgroundColor: column.bg ? "primary.light" : "#FFF",
                        color: column.bg ? "#FFF" : "outline.dark",
                        "@media screen and (max-width:900px)": {
                          display:
                            column.isSmallScreen === true
                              ? "table-cell"
                              : "none",
                        },
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sites?.map((site, rowNum) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={site.id}
                      onClick={() => handleLocationCodeClick(site, rowNum)}
                      sx={{
                        pointerEvents: "none",
                        "@media screen and (max-width:900px)": {
                          cursor: "pointer",
                          pointerEvents: "auto",
                        },
                      }}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={column.label}
                            align={column.align}
                            sx={{
                              padding: "8px",
                              "@media screen and (max-width:900px)": {
                                display:
                                  column.isSmallScreen === true
                                    ? "table-cell"
                                    : "none",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "32px",
                              }}
                            >
                              {column.textField ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <OperatorTextfield
                                    siteData={site}
                                    changedValue={(value) =>
                                      // handleChangeOperator(value, rowNum)
                                      handleChangeOperator(value, site.id)
                                    }
                                  />
                                  <ButtonVaridate rowNum={rowNum} />
                                </Box>
                              ) : column.m_branch ? (
                                <>{site.m_branch[column.id]}</>
                              ) : column.m_sale_organization ? (
                                <>{site.m_sale_organization[column.id]}</>
                              ) : column.m_customer ? (
                                <>{site.m_customer[column.id]}</>
                              ) : column.id === "ss_import_at" ||
                                column.id === "location_import_at" ||
                                column.id === "update_at" ? (
                                formatDate(site[column.id])
                              ) : (
                                <>{site?.[column.id]}</>
                              )}
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={isPopoverOpen}
            onClose={handlePopoverClose}
            className="shogoshogo"
          >
            <Box
              sx={{
                display: "flex",
                height: "56px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderBottom: "2px solid #CCC",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "4px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                基本情報詳細
              </Box>
              <IconButton
                aria-label="close"
                onClick={handlePopoverClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
              <TableBody>
                {selectedRow &&
                  columns.map((column) => {
                    return (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={column.label}
                      >
                        <TableCell
                          align={column.align}
                          sx={{
                            color: "outline.dark",
                            fontWeight: "bold",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              WebkitWritingMode: "horizontal-tb",
                              height: "100%",
                            }}
                          >
                            {column.label}
                          </Box>
                        </TableCell>
                        <TableCell
                          align={column.align}
                          sx={{
                            writingMode: "horizontal-tb",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                            overflowWrap: "break-word",
                          }}
                        >
                          {column.textField ? (
                            <Box sx={{ display: "flex" }}>
                              <OperatorTextfield
                                siteData={sites[selectedRowNum]}
                                changedValue={(value) =>
                                  // handleChangeOperator(value, selectedRowNum)
                                  handleChangeOperator(value, selectedRow.id)
                                }
                              />
                              <ButtonVaridate rowNum={selectedRowNum} />
                            </Box>
                          ) : column.m_branch ? (
                            <>{selectedRow.m_branch[column.id]}</>
                          ) : column.m_sale_organization ? (
                            <>{selectedRow.m_sale_organization[column.id]}</>
                          ) : column.m_customer ? (
                            <>{selectedRow.m_customer[column.id]}</>
                          ) : (
                            <>{selectedRow[column.id]}</>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Dialog>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalData | 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                {
                  margin: "0 !important",
                  padding: "0 !important",
                },
            }}
          />
        </Paper>
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={(e) => putOperator(e)}
        title={"登録確認"}
        contents={"担当者名を登録してよろしいですか？"}
      />
    </>
  );
};
export default SearchResult;
