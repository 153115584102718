import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import dayjs from "dayjs";

const columns = [
  {
    id: "ColumnName",
    label: "カラム名",
    minWidth: "120px",
    isSmallScreen: true,
  },
  {
    id: "JpnName",
    label: "日本語名称",
    minWidth: "80px",
    textfield: true,
    isSmallScreen: false,
  },
  {
    id: "UsName",
    label: "英語名称",
    minWidth: "80px",
    textfield: true,
    isSmallScreen: false,
  },
  {
    id: "buttonSpace",
    label: "",
    minWidth: "80px",
    button: true,
    isSmallScreen: true,
  },
];

const rows = [
  createData("columnName1", "カラム1", "column1", "", 1),
  createData("columnName2", "カラム2", "column2", "", 2),
  createData("columnName3", "カラム3", "column3", "", 3),
  createData("columnName4", "カラム4", "column4", "", 4),
  createData("columnName5", "カラム5", "column5", "", 5),
  createData("columnName6", "カラム6", "column6", "", 6),
];

function createData(ColumnName, JpnName, UsName, buttonSpace, dataId) {
  return {
    ColumnName,
    JpnName,
    UsName,
    buttonSpace,
    dataId,
  };
}

function textFieldValidate(input) {
  return <TextField size="small" defaultValue={input} />;
}

function datepickerValidate(input) {
  const today = dayjs().format("20231223");
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja} //ja追加
      dateFormats={{ monthAndYear: "yyyy/MM/dd" }} //カレンダー左上の日付表示
      localeText={{
        previousMonth: "前月を表示", // < のツールチップ
        nextMonth: "次月を表示", // > のツールチップ
        cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
        okButtonLabel: "選択", // スマホ画面のOKボタン
        datePickerToolbarTitle: "日付選択",
      }}
    >
      <DatePicker
        inputFormat="yyyy-MM-dd" //選択済みの日付の表示
        mask="____年__月__日"
        sx={{
          ".MuiInputBase-root": { height: "40px" },
          width: "100%",
        }}
        // toolbarFormat="yyyy年MM月dd日"
        format="yyyy-MM-dd"
        defaultValue={today}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              inputMode: "numeric",
              ...params.inputProps,
              placeholder: "****年**月**日", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
            }}
          />
        )}
        slotProps={{
          toolbar: {
            toolbarFormat: "yyyy/MM/dd",
          },
          // textField: { InputProps: { color: 'primary' } }
        }}
      />
    </LocalizationProvider>
  );
}

export default function SsNameTable() {
  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // const [popoverData, setPopoverData] = useState(null);

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const buttonVaridate = (input) =>
    input ? (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained">登録</Button>
        <IconButton color="error">
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </Box>
    ) : null;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    // align={"column.align"}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      borderBottom: 2,
                      padding: "8px",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.dataId}
                      onClick={() => handleLocationCodeClick(row)}
                      sx={{
                        pointerEvents: "none",
                        "@media screen and (max-width:900px)": {
                          cursor: "pointer",
                          pointerEvents: "auto",
                        },
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            sx={{
                              padding: "8px",
                              "@media screen and (max-width:900px)": {
                                display:
                                  column.isSmallScreen === true
                                    ? "table-cell"
                                    : "none",
                              },
                            }}
                          >
                            {column.textfield === true ? (
                              textFieldValidate(value)
                            ) : column.datepicker === true ? (
                              datepickerValidate()
                            ) : (
                              <>{value}</>
                            )}
                            {buttonVaridate(column.button)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              名称設定詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {selectedRow &&
                columns.map((column) => {
                  const value = selectedRow[column.id];
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                      <TableCell
                        // align={column.align}
                        sx={{
                          color: "outline.dark",
                          fontWeight: "bold",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            WebkitWritingMode: "horizontal-tb",
                            height: "100%",
                            padding: "8px",
                          }}
                        >
                          {column.label}
                        </Box>
                      </TableCell>
                      <TableCell
                        // align={column.align}
                        sx={{
                          writingMode: "horizontal-tb",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                          overflowWrap: "break-word",
                        }}
                      >
                        <>{value}</>
                        {buttonVaridate(column.button)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Dialog>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          name={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
              margin: "0 !important",
              padding: "0 !important",
            },
          }}
        />
      </Paper>
    </>
  );
}
