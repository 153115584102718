import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Checkbox,
  Button,
  IconButton,
  TablePagination,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import TemplateSelect from "./TemplateSelect";
import DisasterSelect from "./DisasterSelect";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import PopoverCustom from "../../../../components/PopoverCustom";
import PreviewTable from "./PreviewTable";
import { fetchSession } from "../../../../auth";
import { getCurrentDate } from "../../../../utils/getCurrentDate";

const Mailing = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };

  const handleClick = (path) => {
    navigate(path);
  };

  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const mailId = searchParams.get("id");
  const [sendMails, setSendMails] = useState({});

  //日付の入力出力フォーマット調整
  const dateFormatter = (e) => {
    const date = new Date(e);
    if (!date) {
      // eがnullの場合（カレンダーがクリアされた場合）は何もしない
      return null;
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = "00"; // SSは常に"00"に設定
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  };

  const getSendMail = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/mail/${mailId}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("mail_id", mailId);
    axios
      .get(apiUrl, headers)
      .then((response) => {
        const data = response.data.send_mail;
        const status = response.data;
        console.log("メール送信設定情報取得", response);
        setSendMails(data);
        // const mRoleIds = data.send_mail_organizations.map(
        //   (org) => org.m_role_id
        // );
        // const mOrganizationIds = data.send_mail_organizations.map(
        //   (org) => org.m_organization_id
        // );
        const mRoleIds = data.send_mail_organizations
          .filter(
            (org) => org.m_role_id !== null && org.m_role_id !== undefined
          )
          .map((org) => org.m_role_id);

        const mOrganizationIds = data.send_mail_organizations
          .filter(
            (org) =>
              org.m_organization_id !== null &&
              org.m_organization_id !== undefined
          )
          .map((org) => org.m_organization_id);
        const title = data.title;
        const content = data.content;
        const plan_at = dateFormatter(data.plan_at);
        const template_mail_id = data.template_mail_id;
        const disaster_id = data.disaster_id;
        handleChangeTemplate(data);
        setPostContents((prevValue) => ({
          ...prevValue,
          m_role_id: mRoleIds,
          m_organization_id: mOrganizationIds,
          title: title,
          content: content,
          plan_at: plan_at,
          template_mail_id: template_mail_id,
          disaster_id: disaster_id,
        }));
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  // 登録条件
  const [postContents, setPostContents] = useState({
    id: null,
    plan_at: null,
    template_mail_id: null,
    title: null,
    content: null,
    disaster_id: null,
    m_role_id: null,
    m_organization_id: null,
    order: null,
    sort: null,
    page: null,
    limit: null,
  });

  //権限チェックボックスのチェック管理
  const CheckboxValidate = (props) => {
    const { roleId, column, organizationIds } = props;
    const isChecked = postContents[column]?.includes(roleId);

    const handleChange = (e) => {
      setPostContents((prevSearchContents) => {
        const currentMRoleId = prevSearchContents[column] || [];
        let updatedMRoleId = currentMRoleId.slice();

        if (organizationIds) {
          updatedMRoleId = e.target.checked
            ? [...updatedMRoleId, ...organizationIds]
            : updatedMRoleId.filter(
                (checkedId) => !organizationIds.includes(checkedId)
              );
        } else {
          updatedMRoleId = e.target.checked
            ? [...updatedMRoleId, roleId]
            : updatedMRoleId.filter((checkedId) => checkedId !== roleId);
        }

        return {
          ...prevSearchContents,
          [column]: updatedMRoleId,
          [column === "m_role_id" ? "m_organization_id" : "m_role_id"]: null,
        };
      });
    };

    return (
      <Checkbox
        size="small"
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    );
  };

  //権限情報取得
  const [roles, setRoles] = useState(null);
  const getRoles = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/roles`;
    const params = {
      params: { order: null, sort: null, page: 1 },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("権限情報取得入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.m_roles;
        console.log("権限情報情報取得", response);
        setRoles(data);
        console.log("data", data);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //選択テンプレート情報管理
  const handleChangeTemplate = (e) => {
    console.log("選択テンプレート", e);
    setPostContents((prev) => ({
      ...prev,
      title: e.title,
      content: e.content,
      template_mail_id: e.id,
    }));
  };

  //選択した災害名称管理
  const handleChangeDisaster = (e) => {
    setPostContents((prev) => ({
      ...prev,
      disaster_id: e.id,
    }));
  };

  //組織情報取得
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalData, setTotalData] = useState();

  //検索結果ページネーション
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getOrganizations = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/organizations`;
    const params = {
      params: {
        parent_m_organization_id: 0,
        m_branch_id: null,
        m_role_id: null,
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("組織情報取得入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        console.log("組織情報情報取得", response);
        const data = response.data.m_organizations;
        const total = response.data.total_count;
        setOrganizations(data);
        setTotalData(total);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  const [pageValidate, setPageValidate] = useState(0);
  const settingTemplate = () => {
    setPageValidate(1);
    getSendMail();
  };

  useEffect(() => {
    if (mailId === null) {
      setPageValidate(0);
      // 現在日時を初期値として設定
      const formattedDate = getCurrentDate();
      setPostContents((prevValue) => ({
        ...prevValue,
        plan_at: formattedDate,
      }));
    } else {
      settingTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoles();
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const renderTree = (organization) => {
    // チェックボックスに渡すすべてのidを含む配列を作成する
    const flattenOrganizationIds = (org) => {
      let ids = [];
      if (org.id) {
        ids.push(org.id);
      }
      if (Array.isArray(org.child_m_organizations)) {
        org.child_m_organizations.forEach((childOrg) => {
          ids = ids.concat(flattenOrganizationIds(childOrg));
        });
      }
      return ids;
    };

    const organizationIds = flattenOrganizationIds(organization);

    if (!organization) {
      return null;
    }

    return (
      <Box
        key={organization.id}
        sx={{ display: "flex", alignItems: "flex-start", padding: "0" }}
      >
        <TreeItem
          nodeId={String(organization.id)}
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckboxValidate
                roleId={organization.id}
                column={"m_organization_id"}
                parentOrganizationid={organization.parent_m_organization_id}
                organizationIds={organizationIds}
              />
              {organization.group_name === null
                ? organization.department_name
                : organization.department_name + "-" + organization.group_name}
            </Box>
          }
          sx={{
            display: "flex",
            flexDirection: "column",
            ".MuiTreeItem-iconContainer": {
              display: "none",
            },
            ".css-1vbkhv8-MuiTreeItem-content": {
              padding: "0",
            },
            ".css-1vbkhv8-MuiTreeItem-content .MuiTreeItem-label": {
              padding: "0",
            },
          }}
        >
          {Array.isArray(organization.child_m_organizations)
            ? organization.child_m_organizations.map((childOrganization) =>
                renderTree(childOrganization)
              )
            : null}
        </TreeItem>
      </Box>
    );
  };

  //タイトル情報
  const handleChangeTitle = (e) => {
    const newValue = e.target.value;
    setPostContents((prevValue) => ({
      ...prevValue,
      title: newValue,
    }));
  };

  //送信日時情報
  // 即時チェックボックス操作時
  const CheckboxCalender = () => {
    const handleChange = (e) => {
      if (e.target.checked) {
        // チェックがついたら plan_at を null にして即時送信
        setPostContents((prevPostContents) => ({
          ...prevPostContents,
          plan_at: null,
        }));
      } else {
        // チェックが外れたら現在の日時を設定（またはカレンダーを表示して選択させる）
        const formattedDate = getCurrentDate();
        setPostContents((prevPostContents) => ({
          ...prevPostContents,
          plan_at: formattedDate, // ここでデフォルトの日時を設定（現在時刻など）
        }));
      }
    };

    return (
      <Checkbox
        size="small"
        checked={postContents.plan_at === null} // plan_at が null のときにチェックがつく
        onClick={(e) => e.stopPropagation()}
        onChange={handleChange} // handleChange を呼び出して状態を更新
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    );
  };

  //カレンダー操作時
  const handleChangeCalender = (e) => {
    if (!e) {
      // eがnullの場合（カレンダーがクリアされた場合）は何もしない
      return;
    }

    const year = e.getFullYear();
    const month = (e.getMonth() + 1).toString().padStart(2, "0");
    const day = e.getDate().toString().padStart(2, "0");
    const hours = e.getHours().toString().padStart(2, "0");
    const minutes = e.getMinutes().toString().padStart(2, "0");
    const seconds = "00"; // SSは常に"00"に設定

    const formattedDateStr = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    console.log("eventlog", formattedDateStr);

    setPostContents((prevValue) => ({
      ...prevValue,
      plan_at: formattedDateStr,
    }));
  };

  //内容情報
  const handleChangeContent = (e) => {
    const newValue = e.target.value;
    setPostContents((prevValue) => ({
      ...prevValue,
      content: newValue,
    }));
  };

  //登録確認ポップアップ
  const [confirmPopoverOpen, setPopoverSplitOpen] = useState(false);
  const putPostButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const confirmPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };

  //メール送信設定情報登録
  const putPostMail = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/mail`;
    const method = mailId ? "PUT" : "POST";
    const params = {
      id: mailId ? parseInt(mailId) : null,
      plan_at: postContents["plan_at"],
      template_mail_id: postContents["template_mail_id"],
      title: postContents["title"],
      content: postContents["content"],
      disaster_id: postContents["disaster_id"],
      m_role_id: postContents["m_role_id"],
      m_organization_id: postContents["m_organization_id"],
      order: null,
      sort: null,
      page: null,
      limit: null,
    };

    const axiosConfig = {
      method: method,
      url: apiUrl,
      data: params,
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
      // 他に必要なヘッダーや認証情報があれば追加
    };

    console.log("入力データ", params);
    isSuccess
      ? axios(axiosConfig)
          .then((response) => {
            console.log("レスポンスデータ:", response.data);
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            if (
              error.response.status === 400 &&
              error.response.data.status === "ng"
            ) {
              console.log("ステータスの組み合わせが重複しています");
              console.log("エラーメッセージ:", error.response.data.message);
            } else {
              console.error("エラーが発生しました:", error);
            }
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません;
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const checkConsole = () => {
    console.log("postContents", postContents);
    console.log("roles:", roles);
    console.log("sendMails:", sendMails);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: 5,
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          メール送信履歴
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          /メール{pageValidate === 1 ? <>更新</> : <>送信</>}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "16px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              paddingTop: "16px",
              paddingRight: "16px",
              paddingLeft: "16px",
              paddingBottom: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            送信内容設定
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "48px",
                "@media screen and (max-width:600px)": {
                  flexDirection: "column",
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                  maxWidth: "480px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    minWidth: "min-content",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    "@media screen and (max-width:900px)": {
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    },
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "160px",
                      padding: "8px 0 0 0",
                      "@media screen and (max-width:900px)": {
                        padding: "0",
                      },
                    }}
                  >
                    権限
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Box>
                      {roles?.map((role) => (
                        <Box
                          key={role.name}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "500",
                          }}
                        >
                          <CheckboxValidate
                            roleId={role.id}
                            column={"m_role_id"}
                          />
                          {role["name"]}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "min-content",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    "@media screen and (max-width:900px)": {
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    },
                  }}
                >
                  <Box sx={{ minWidth: "160px" }}>件名</Box>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{
                      readOnly: true,
                    }}
                    value={postContents?.["title"] || ""}
                    onChange={(e) => handleChangeTitle(e)}
                    sx={{
                      color: "#000",
                      height: "40px",
                      width: "100%",
                      ".MuiInputBase-root": {
                        height: "100%",
                      },
                      "#outlined-basic": {
                        boxSizing: "border-box",
                        height: "100%",
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "min-content",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    "@media screen and (max-width:900px)": {
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      minWidth: "160px",
                      ".MuiFormControl-root": {
                        width: "100% !importnat",
                      },
                    }}
                  >
                    送信日時
                  </Box>
                  <Box sx={{ display: "flex", gap: "8px", width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CheckboxCalender />
                      即時
                    </Box>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ja} //ja追加
                      dateFormats={{ monthAndYear: "yyyy/MM" }} //カレンダー左上の日付表示
                      localeText={{
                        previousMonth: "前月を表示", // < のツールチップ
                        nextMonth: "次月を表示", // > のツールチップ
                        cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
                        okButtonLabel: "選択", // スマホ画面のOKボタン
                        datePickerToolbarTitle: "日付選択",
                      }}
                    >
                      <DateTimePicker
                        inputFormat="yyyy-MM-dd" //選択済みの日付の表示
                        mask="____年__月__日"
                        toolbarFormat="yyyy年MM月dd日"
                        textFieldProps={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            inputProps={{
                              inputMode: "numeric",
                              ...params.inputProps,
                              placeholder: "****年**月**日", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
                            }}
                          />
                        )}
                        value={
                          postContents.plan_at
                            ? new Date(postContents.plan_at)
                            : null
                        }
                        onChange={(e) => handleChangeCalender(e)}
                        slotProps={{
                          toolbar: {
                            toolbarFormat: "yyyy/MM/dd",
                          },
                          textField: { size: "small" },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "min-content",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    "@media screen and (max-width:900px)": {
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "160px",
                    }}
                  >
                    テンプレート
                    <IconButton
                      color="primary"
                      onClick={() => handleClick("/mail/template")}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                  <TemplateSelect
                    changedValue={(value) => handleChangeTemplate(value)}
                    value={postContents.template_mail_id}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "min-content",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    "@media screen and (max-width:900px)": {
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    },
                  }}
                >
                  <Box sx={{ minWidth: "160px" }}>添付災害スレッド</Box>
                  <DisasterSelect
                    changedValue={(value) => handleChangeDisaster(value)}
                    value={postContents.disaster_id}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                  maxWidth: "480px",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      minWidth: "min-content",
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#000",
                      whiteSpace: "nowrap",
                      "@media screen and (max-width:900px)": {
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "flex-start",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "160px",
                        padding: "8px 0 0 0",
                        "@media screen and (max-width:900px)": {
                          padding: "0",
                        },
                      }}
                    >
                      会社・部署・Gr
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "100%",
                          fontWeight: "500",
                        }}
                      >
                        <TreeView
                          defaultCollapseIcon={<ExpandMoreIcon />}
                          defaultExpandIcon={<ChevronRightIcon />}
                          multiSelect
                        >
                          {Array.isArray(organizations)
                            ? organizations?.map((organization) =>
                                renderTree(organization)
                              )
                            : null}
                        </TreeView>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      minWidth: "min-content",
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#000",
                      whiteSpace: "nowrap",
                      "@media screen and (max-width:900px)": {
                        flexDirection: "column",
                        width: "100%",
                      },
                    }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={totalData | 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{
                        ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                          {
                            margin: "0 !important",
                            padding: "0 !important",
                          },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    minWidth: "min-content",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    height: "fit-content",
                    "@media screen and (max-width:900px)": {
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    },
                  }}
                >
                  <Box sx={{ minWidth: "160px", padding: "8px 0 0 0" }}>
                    本文{" "}
                  </Box>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{
                      readOnly: true,
                    }}
                    multiline
                    rows={4}
                    value={postContents?.["content"] || ""}
                    onChange={(e) => handleChangeContent(e)}
                    sx={{
                      height: "100%",
                      width: "100%",
                      ".MuiInputBase-root": {
                        height: "100%",
                      },
                      "#outlined-basic": {
                        boxSizing: "border-box",
                        height: "100%",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "16px",
                "@media screen and (max-width:600px)": {
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Button
                variant="contained"
                sx={{ width: "208px" }}
                onClick={putPostButtonClick}
              >
                {pageValidate === 1 ? <>更新</> : <>登録</>}
              </Button>
              {/*{process.env.NODE_ENV === "development" && (*/}
              {/*  <Button sx={{ width: "208px" }} onClick={checkConsole}>*/}
              {/*    コンソール*/}
              {/*  </Button>*/}
              {/*)}*/}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: 2,
            paddingRight: 5,
            paddingLeft: 5,
            paddingBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            送信先プレビュー
          </Box>
        </Box>
        <PreviewTable roleOrOrganization={postContents} />
      </Box>

      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={putPostMail}
        title={mailId ? "更新確認" : "登録確認"}
        contents={mailId ? "更新しますか？" : "登録しますか？"}
      />
    </>
  );
};

export default Mailing;
