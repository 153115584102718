import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  Dialog,
  IconButton,
} from "@mui/material";
import {
  Close as CloseIcon,
  EmailOutlined as EmailOutlinedIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SnackbarCustom from "../../../components/SnackBarCustom";
import { fetchSession } from "../../../auth";
import { formatDate } from "../../../utils/deteUtils";

const columns = [
  {
    id: "plan_at",
    label: "送信予定日時",
    minWidth: "fit-content",
    isSmallScreen: true,
  },
  {
    id: "sent_at",
    label: "送信日時",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "send_mail_organizations",
    label: "連絡先",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: true,
    organizations: true,
  },
  {
    id: "title",
    label: "件名",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "disaster_id",
    label: "添付災害スレッド",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
    disaster: true,
  },
  {
    id: "sent_status",
    label: "",
    minWidth: "fit-content",
    align: "left",
    button: true,
    isSmallScreen: true,
  },
];

const SearchResult = (props) => {
  const { searchContents } = props;

  const navigate = useNavigate();
  const handleNavigate = (e, path) => {
    e.stopPropagation();
    navigate(path);
  };

  //メール履歴一覧取得

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalData, setTotalData] = useState();
  const [mails, setMails] = useState([]);

  const getMails = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/mails`;
    const params = {
      params: {
        sent_at_stt: searchContents["sent_at_stt"],
        sent_at_end: searchContents["sent_at_end"],
        title: searchContents["title"],
        m_role_id: searchContents["m_role_id"],
        m_organization_id: searchContents["m_organization_id"],
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("メール送信履歴入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.send_mails;
        const total = response.data.total_count;
        const status = response.data;
        console.log("メール送信履歴取得", response);
        setMails(data);
        setTotalData(total);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //初回起動時の読み込み回避
  const isFirstRender = useRef(false);

  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false; // もう初回レンダーじゃないよ代入
    } else {
      getMails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //ページネーション
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //連絡先（権限）カラム表示内容
  const Organizations = (props) => {
    const { organizations, mailId } = props;
    return (
      <Box>
        {Array.isArray(organizations)
          ? organizations.map((organization) => {
              return (
                <Box
                  key={
                    organization.m_role.id === 0
                      ? mailId +
                        "-" +
                        organization.m_organization.id +
                        "-organization"
                      : mailId + "-" + organization.m_role.id + "-role"
                  }
                  sx={{ color: "#0CA3B9", fontWeight: "700" }}
                >
                  {organization.m_role.id === 0
                    ? organization.m_organization.department_name
                    : organization.m_role.name}
                </Box>
              );
            })
          : null}
      </Box>
    );
  };

  const ButtonVaridate = (props) => {
    const { input, id } = props;
    // 送信ステータス(0:未送信, 1:送信中, 2:送信完了, 9:送信エラー)
    // return input !== 2 ? (
    return input === 2 ? (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          sx={{ pointerEvents: "auto" }}
          // onClick={() => registerButtonClick(rowNum)}
        >
          確認
        </Button>
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          sx={{ pointerEvents: "auto" }}
          // onClick={(e) => e.stopPropagation()}
          onClick={(e) => handleNavigate(e, `/mail/log/mailing?id=${id}`)}
          // onClick={() => handleLocationCodeClick(rowNum)}
        >
          編集
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          gap: "16px",
          padding: "16px 0 32px",
          "@media screen and (max-width:900px)": {
            padding: "16px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "16px",
            "@media screen and (max-width:600px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Button
            variant="contained"
            onClick={getMails}
            sx={{ width: "208px" }}
          >
            検索
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => handleNavigate(e, "/mail/log/mailing")}
            sx={{ display: "flex", width: "208px", gap: "8px" }}
          >
            <EmailOutlinedIcon />
            メール作成
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索結果
          </Box>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{
                        padding: "8px",
                        fontWeight: "bold",
                        borderBottom: 2,
                        borderBottomColor: "outline.dark",
                        "@media screen and (max-width:900px)": {
                          display:
                            column.isSmallScreen === true
                              ? "table-cell"
                              : "none",
                        },
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {mails?.map((mail) => {
                  return (
                    <TableRow
                      // hover
                      role="checkbox"
                      tabIndex={-1}
                      key={mail.id}
                      onClick={() => handleLocationCodeClick(mail)}
                      sx={{
                        pointerEvents: "none",
                        "@media screen and (max-width:900px)": {
                          cursor: "pointer",
                          pointerEvents: "auto",
                        },
                      }}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={`${column.label}_${column.id}`}
                            align={column.align}
                            sx={{
                              padding: "8px",
                              "@media screen and (max-width:900px)": {
                                display:
                                  column.isSmallScreen === true
                                    ? "table-cell"
                                    : "none",
                              },
                            }}
                          >
                            {column.button ? (
                              <ButtonVaridate
                                input={mail.sent_status}
                                id={mail.id}
                              />
                            ) : column.organizations ? (
                              <Organizations
                                organizations={mail?.send_mail_organizations}
                                mailId={mail.id}
                              />
                            ) : column.disaster ? (
                              <>{mail?.disaster?.name}</>
                            ) : column.id === "sent_at" ||
                              column.id === "plan_at" ? (
                              formatDate(mail?.[column.id])
                            ) : (
                              <>{mail?.[column.id]}</>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <SnackbarCustom
            data={snackBarstatus}
            openStatus={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          />
          <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
            <Box
              sx={{
                display: "flex",
                height: "56px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderBottom: "2px solid #CCC",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "8px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                メール送信履歴詳細
              </Box>
              <IconButton
                aria-label="close"
                onClick={handlePopoverClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
              <TableBody>
                {selectedRow &&
                  columns.slice(0, 5).map((column) => {
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                        <TableCell
                          align={column.align}
                          sx={{
                            color: "outline.dark",
                            fontWeight: "bold",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              WebkitWritingMode: "horizontal-tb",
                              height: "100%",
                            }}
                          >
                            {column.label}
                          </Box>
                        </TableCell>
                        <TableCell
                          align={column.align}
                          sx={{
                            writingMode: "horizontal-tb",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                            overflowWrap: "break-word",
                          }}
                        >
                          {column.organizations ? (
                            <Organizations
                              organizations={
                                selectedRow.send_mail_organizations
                              }
                            />
                          ) : column.id === "sent_at" ||
                            column.id === "plan_at" ? (
                            formatDate(selectedRow?.[column.id])
                          ) : (
                            <>{selectedRow?.[column.id]}</>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Dialog>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalData | 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                {
                  margin: "0 !important",
                  padding: "0 !important",
                },
            }}
          />
        </Paper>
      </Box>
    </>
  );
};
export default SearchResult;
