import * as React from "react";
import { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  IconButton,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import SnackbarCustom from "../../components/SnackBarCustom";
import { formatDate } from "../../utils/deteUtils";
import { fetchSession } from "../../auth";

const columns = [
  {
    id: "pref_name",
    label: "都道府県",
    minWidth: 136,
    isSmallScreen: true,
  },
  {
    id: "city_name",
    label: "地区町村",
    minWidth: 80,
    isSmallScreen: true,
    kind: true,
    m_division: true,
  },
  {
    id: "name",
    label: "対象災害",
    minWidth: 112,
    align: "left",
    isSmallScreen: true,
    disaster: true,
  },
  {
    id: "site_count",
    label: "対象SS",
    minWidth: 240,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
    operation: true,
    isSmallScreen: false,
  },
  {
    id: "update_at",
    label: "登録日時",
    minWidth: 240,
    align: "left",
    format: (value) => value.toFixed(2),
    buttons: true,
    isSmallScreen: false,
  },
  {
    id: "create_user",
    label: "登録者",
    minWidth: 172,
    align: "left",
    isSmallScreen: false,
    create_user: true,
  },
];

export default function ManualDisasterList() {
  const [selectedRow, setSelectedRow] = useState([]);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (disaster) => {
    setSelectedRow(disaster);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };
  //取得一覧ページネーション管理
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //一覧取得
  const [disasters, setDisasters] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const getDisasterManual = async () => {
    const tokenInfo = await fetchSession();

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-manual-triggers`;

    const params = {
      params: {
        attach_status: [0],
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        // レスポンスのデータを取得
        const data = response.data.disaster_manual_triggers;
        const totalCount = response.data.total_count;
        const status = response.data;
        // テナント情報をステートにセット
        setDisasters(data);
        setTotalCount(totalCount);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getDisasterManual();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    color: "outline.dark",
                    fontWeight: "bold",
                    borderBottom: 2,
                    padding: "8px",
                    "@media screen and (max-width:900px)": {
                      display:
                        column.isSmallScreen === true ? "table-cell" : "none",
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {disasters?.map((disaster, rowNum) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={disaster.id}
                  onClick={() => handleLocationCodeClick(disaster)}
                  sx={{
                    pointerEvents: "none",
                    "@media screen and (max-width:900px)": {
                      cursor: "pointer",
                      pointerEvents: "auto",
                    },
                  }}
                >
                  {columns.map((column, index) => {
                    return (
                      <TableCell
                        key={disaster.id + column.id}
                        align={column.align}
                        sx={{
                          padding: "8px",
                          "@media screen and (max-width:900px)": {
                            display:
                              column.isSmallScreen === true
                                ? "table-cell"
                                : "none",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "24px",
                          }}
                        >
                          {column.disaster ? (
                            <>{disaster.disaster[column.id]}</>
                          ) : column.m_division ? (
                            <>{disaster?.m_division?.[column.id]}</>
                          ) : column.id === "update_at" ? (
                            formatDate(disaster[column.id])
                          ) : column.create_user ? (
                            <>{disaster?.create_user?.name}</>
                          ) : (
                            <>{disaster[column.id]}</>
                          )}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
        <Box
          sx={{
            display: "flex",
            height: "56px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #CCC",
          }}
        >
          <Box
            sx={{
              paddingLeft: "8px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            対応中災害詳細
          </Box>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
          <TableBody>
            {selectedRow &&
              columns.map((column) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                    <TableCell
                      align={column.align}
                      sx={{
                        color: "outline.dark",
                        fontWeight: "bold",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          WebkitWritingMode: "horizontal-tb",
                          height: "100%",
                        }}
                      >
                        {column.label}
                      </Box>
                    </TableCell>
                    <TableCell
                      align={column.align}
                      sx={{
                        writingMode: "horizontal-tb",
                        boxSizing: "border-box",
                        width: "calc((100% - 64px) / 2)",
                        overflowWrap: "break-word",
                      }}
                    >
                      {column.disaster ? (
                        <>{selectedRow?.disaster?.[column.id]}</>
                      ) : column.m_division ? (
                        <>{selectedRow?.m_division?.[column.id]}</>
                      ) : column.id === "update_at" ? (
                        formatDate(selectedRow[column.id])
                      ) : column.create_user ? (
                        <>{column?.create_user?.name}</>
                      ) : (
                        <>{selectedRow[column.id]}</>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Dialog>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount | 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
            margin: "0 !important",
            padding: "0 !important",
          },
        }}
      />
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Paper>
  );
}
