import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Dialog,
  IconButton,
  TablePagination,
  Tooltip,
} from "@mui/material";
import {
  Close as CloseIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  EditLocationAlt as EditLocationAltIcon,
} from "@mui/icons-material";
import PopoverRegister from "../../components/PopoverRegister";
import PopoverDelete from "../../components/PopoverDelete";
import axios from "axios";
import TextFieldValidate from "./TextFieldValidate";
import DatepickerValidate from "./DatepickerValidate";
import SnackbarCustom from "../../components/SnackBarCustom";
import { fetchSession } from "../../auth";

const columns = [
  {
    id: "name",
    label: "会社名",
    minWidth: "80px",
    textfield: true,
    isSmallScreen: true,
  },
  {
    id: "code",
    label: "識別子",
    minWidth: "80px",
    textfield: true,
    isSmallScreen: true,
  },
  {
    id: "manager_name",
    label: "契約担当者",
    minWidth: "80px",
    textfield: true,
    isSmallScreen: false,
  },
  {
    id: "manager_tel",
    label: "担当者連絡先",
    minWidth: "120px",
    textfield: true,
    isSmallScreen: false,
  },
  {
    id: "contract_start_at",
    label: "契約開始年月日",
    minWidth: "80px",
    datepicker: true,
    isSmallScreen: false,
  },
  {
    id: "contract_end_at",
    label: "契約終了年月日",
    minWidth: "80px",
    datepicker: true,
    isSmallScreen: false,
  },
  {
    id: "buttonSpace",
    label: "",
    minWidth: "176px",
    button: true,
    isSmallScreen: true,
  },
];

export default function SubscriberTable() {
  // ページネーション
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  //更新時の選択行（登録ボタンを押下した行）
  const [selectedRowNum, isSelectedRowNum] = useState("");
  const [registerPopoverOpen, setRegisterPopoverOpen] = useState(false);
  const registerButtonClick = (rowNum) => {
    isSelectedRowNum(rowNum);
    setRegisterPopoverOpen(true);
  };
  const registerPopupClose = (event) => {
    setRegisterPopoverOpen(false);
  };

  // テナント一覧取得
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tenants, setTenants] = useState([]);
  const [totalData, setTotalData] = useState();

  const getTenantsData = async() => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/tenants`;
    const params = {
      params: { code: "", name: "", page: page + 1, limit: rowsPerPage },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };

    axios
      .get(apiUrl, params)
      .then((response) => {
        // レスポンスのデータを取得
        const data = response.data.m_tenants;
        const total = response.data.total_count;
        const status = response.data;
        console.log("テナント一覧取得", response);
        console.log("total_dataデータ型", typeof total);
        // テナント情報をステートにセット
        setTenants(data);
        setTotalData(total);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getTenantsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  // 更新処理
  const postTenantData = async(isSuccess) => {
    const tokenInfo = await fetchSession();
    // 新しいフォーマットの日付文字列を作成
    var contract_start_at = new Date(
      tenants[selectedRowNum]?.contract_start_at
    );
    var year = contract_start_at?.getFullYear();
    var month = (contract_start_at?.getMonth() + 1).toString().padStart(2, "0");
    var day = contract_start_at?.getDate().toString().padStart(2, "0");
    var formattedDateStr = year + "/" + month + "/" + day;

    var contract_end_at = new Date(tenants[selectedRowNum]?.contract_end_at);
    var year2 = contract_end_at?.getFullYear();
    var month2 = (contract_end_at?.getMonth() + 1).toString().padStart(2, "0");
    var day2 = contract_end_at?.getDate().toString().padStart(2, "0");
    var formattedDateEnd = year2 + "/" + month2 + "/" + day2;
    console.log(tenants[selectedRowNum]);

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/tenant?id=${tenants[selectedRowNum].id}`;
    //更新データセット
    const updatedTenantData = {
      id: tenants[selectedRowNum]["id"], // 更新するテナントのID
      code: tenants[selectedRowNum]["code"], // 新しいコード
      name: tenants[selectedRowNum]["name"], // 新しいテナント名
      manager_name: tenants[selectedRowNum]["manager_name"], // 新しい担当者名
      manager_tel: tenants[selectedRowNum]["manager_tel"], // 新しい担当者電話番号
      contract_start_at: formattedDateStr, // 新しい契約開始日 (例: "2023-10-27")
      contract_end_at: formattedDateEnd, // 新しい契約終了日 (必要に応じて設定)
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("updatedTenantData", updatedTenantData);
    isSuccess
      ? axios
          .put(apiUrl, updatedTenantData, headers)
          .then((response) => {
            console.log("レスポンスデータ:", response.data);
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません");
  };

  // 削除処理
  const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);
  const deleteButtonClick = (tenantId) => {
    // event.stopPropagation();
    setDeleteTenantId(tenantId);
    setDeletePopoverOpen(true);
  };
  const deletePopupClose = (event) => {
    // event.stopPropagation();
    setDeletePopoverOpen(false);
  };
  const [deleteTenantId, setDeleteTenantId] = useState("");
  // const headers = {
  //   headers: {
  //     Authorization: `Bearer ${tokenInfo.accessToken}`,
  //   },
  // };
  const handleDelete = async(isSuccess) => {
    const tokenInfo = await fetchSession();
    isSuccess
      ? axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/tenant?id=${deleteTenantId}`,
            {
              headers: {
                Authorization: `Bearer ${tokenInfo.accessToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status === "ok") {
              console.log(`テナント ${deleteTenantId} を削除しました`);
              getTenantsData();
              // 成功時の処理を追加
            } else if (response.data.status === "ng") {
              console.error("指定したテナントは存在しません");
              // エラー時の処理を追加
            }
          })
          .catch((error) => {
            console.error("リクエストエラー:", error);
            // リクエストが失敗した場合の処理を追加
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  const handleChange = (inputValue, tenantId, rowNum, column) => {
    // 変更内容とテナントIDを返り値として受け取り
    console.log("column", typeof column);
    const updatedArray = [...tenants]; // オリジナルの配列をコピー
    const updatedItem = { ...updatedArray[rowNum] }; // インデックス0の要素をコピー
    updatedItem[column] = inputValue;
    updatedArray[rowNum] = updatedItem; // インデックス0の要素を更新したものに置き換え
    // 更新対象のrowNumセット
    // setRowNum(rowNum);
    setTenants(updatedArray);
    console.log(inputValue, tenantId, rowNum);
    console.log("tenants", tenants);
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (rowNum) => {
    isSelectedRowNum(rowNum);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const buttonVaridate = (input, tenant, rowNum) =>
    input ? (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          width: "100%",
          justifyContent: "flex-end",
          ".edit-button": {
            display: "none",
          },
          "@media screen and (max-width:900px)": {
            ".submit-button": {
              display: "none",
            },
            ".edit-button": {
              display: "unset",
            },
          },
        }}
      >
        <Button
          variant="contained"
          className="submit-button"
          sx={{ pointerEvents: "auto" }}
          onClick={() => registerButtonClick(rowNum)}
        >
          登録
        </Button>
        <Button
          variant="contained"
          className="edit-button"
          sx={{ pointerEvents: "auto" }}
          onClick={() => handleLocationCodeClick(rowNum)}
        >
          編集
        </Button>
        <Tooltip title="アイコン編集">
          <IconButton
            color="primary"
            sx={{ pointerEvents: "auto" }}
            onClick={() =>
              handleClick(
                `/tenant/setting?tenant_id=${tenant.id}&name=${tenant.name}`
              )
            }
          >
            <EditLocationAltIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          color="error"
          sx={{ pointerEvents: "auto" }}
          onClick={() => deleteButtonClick(tenant.id)}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </Box>
    ) : null;

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    color: "outline.dark",
                    fontWeight: "bold",
                    borderBottom: 2,
                    padding: "8px",
                    "@media screen and (max-width:900px)": {
                      display:
                        column.isSmallScreen === true ? "table-cell" : "none",
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants?.map((tenant, rowNum) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={tenant.id}
                  sx={{
                    marginTop: "8px",
                    pointerEvents: "none",
                  }}
                >
                  {columns?.map((column) => {
                    return (
                      <TableCell
                        key={column.id}
                        sx={{
                          padding: "8px",
                          "@media screen and (max-width:900px)": {
                            display:
                              column.isSmallScreen === true
                                ? "table-cell"
                                : "none",
                          },
                        }}
                      >
                        {column.textfield === true ? (
                          <TextFieldValidate
                            input={tenant}
                            column={column.id}
                            changedValue={(value) =>
                              handleChange(value, tenant.id, rowNum, column.id)
                            }
                          />
                        ) : column.datepicker === true ? (
                          <DatepickerValidate
                            input={tenant}
                            column={column.id}
                            changedValue={(value) =>
                              handleChange(value, tenant.id, rowNum, column.id)
                            }
                          />
                        ) : null}
                        {buttonVaridate(column.button, tenant, rowNum)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isPopoverOpen}
        onClose={handlePopoverClose}
        sx={{
          ".css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "56px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #CCC",
          }}
        >
          <Box
            sx={{
              paddingLeft: "8px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            契約者マスタ詳細
          </Box>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
          <TableBody>
            {columns?.map((column) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                  <TableCell
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      boxSizing: "border-box",
                      width: "calc((100vw - 64px) / 2)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        WebkitWritingMode: "horizontal-tb",
                        height: "100%",
                        padding: "8px",
                      }}
                    >
                      {column.label}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      writingMode: "horizontal-tb",
                      boxSizing: "border-box",
                      width: "calc((100% - 64px) / 2)",
                      overflowWrap: "break-word",
                      ".edit-button": {
                        display: "none",
                      },
                      ".submit-button": {
                        display: "unset",
                      },
                    }}
                  >
                    {column.textfield === true ? (
                      <TextFieldValidate
                        input={tenants?.[selectedRowNum]}
                        column={column.id}
                        changedValue={(value) =>
                          handleChange(
                            value,
                            tenants?.[selectedRowNum]?.["id"],
                            selectedRowNum,
                            column.id
                          )
                        }
                      />
                    ) : column.datepicker === true ? (
                      <DatepickerValidate
                        input={tenants?.[selectedRowNum]}
                        column={column.id}
                        changedValue={(value) =>
                          handleChange(
                            value,
                            tenants?.[selectedRowNum]?.["id"],
                            selectedRowNum,
                            column.id
                          )
                        }
                      />
                    ) : null}
                    {buttonVaridate(
                      column.button,
                      tenants?.[selectedRowNum],
                      selectedRowNum
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Dialog>
      {/* {errorDetails ? (
        //レスポンスメッセージのvalueのみ抜き出し
        Object.values(errorDetails)?.map((errorDetail, index) => (
          <Snackbar
            key={errorDetail}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{ marginTop: `${index * 56}px` }}
          >
            <Alert
              // onClose={handleCloseSnackbar}
              severity="error"
              sx={{ width: "100%" }}
              elevation={6}
            >
              {errorDetail}
            </Alert>
          </Snackbar>
        ))
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )} */}
      <PopoverDelete
        open={deletePopoverOpen}
        onClose={deletePopupClose}
        yesNo={handleDelete}
      />
      <PopoverRegister
        open={registerPopoverOpen}
        onClose={registerPopupClose}
        yesNo={(isSuccess) => postTenantData(isSuccess)}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        //totalDataがnull、undefined時に対応
        count={totalData | 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
            margin: "0 !important",
            padding: "0 !important",
          },
        }}
      />
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Paper>
  );
}
