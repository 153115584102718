import * as React from "react";
import {useState, useEffect, useRef, useContext} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  Dialog,
  IconButton,
  TextField,
  Divider,
} from "@mui/material";
import {
  Close as CloseIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import ja from "date-fns/locale/ja";
import SnackbarCustom from "../../components/SnackBarCustom";
import axios from "axios";
import PopoverCustom from "../../components/PopoverCustom";
import { formatDate } from "../../utils/deteUtils";
import { fetchSession } from "../../auth";
import {DashboardRole, DisasterRole} from "../../role";
import {TokenContext} from "../../TokenContext";

const columns = [
  {
    id: "create_at",
    label: "災害対応日時（始）",
    minWidth: "144px",
    isSmallScreen: false,
  },
  {
    id: "update_at",
    label: "災害対応日時（至）",
    minWidth: "144px",
    isSmallScreen: false,
  },
  {
    id: "kind",
    label: "災害種別",
    minWidth: "96px",
    align: "left",
    kind: true,
    isSmallScreen: true,
  },
  {
    id: "name",
    label: "災害名",
    minWidth: "184px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
    textField: true,
    isSmallScreen: true,
  },
  {
    id: "disaster_status_count",
    label: "災害SS数",
    minWidth: "80px",
    align: "left",
    format: (value) => value.toFixed(2),
    isSmallScreen: false,
  },
  {
    id: "complete_at",
    label: "完了日",
    minWidth: "144px",
    align: "left",
    format: (value) => value.toFixed(2),
    isSmallScreen: false,
  },
  {
    id: "user_name",
    label: "完了実施者",
    minWidth: "152px",
    align: "left",
    format: (value) => value.toFixed(2),
    isSmallScreen: false,
    complete_user: true,
  },
  {
    id: "buttonSpace",
    label: "",
    minWidth: "160px",
    align: "right",
    format: (value) => value.toFixed(2),
    buttons: true,
    isSmallScreen: false,
    position: "sticky",
    right: 0,
    backgroundColor: "#fff",
    zIndex: 1,
  },
];

const DisasterTextfield = (props) => {
  const { disasterData, changedValue, readOnly } = props;
  const handleChange = (e) => {
    if (typeof changedValue === "function") {
      changedValue(e.target.value);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "8px" }}>
      <TextField
        size="small"
        variant="outlined"
        value={disasterData?.name}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ pointerEvents: "auto" }}
        InputProps={{
          readOnly: readOnly, // ここでreadOnlyを設定
        }}
      />
    </Box>
  );
};

const SearchResult = () => {
  //ページネーション管理
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //検索条件
  const [searchTerms, setSearchTerms] = useState([]);

  const [disasters, setDisasters] = useState([]);
  //災害情報一覧取得
  const [totalCount, setTotalCount] = useState();
  const getDisasterData = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disasters`;
    const params = {
      params: {
        name: searchTerms["name"], // 災害名称があいまい検索
        is_completed: true, // true: 完了した災害情報, false: 未完了の災害
        exclude_id: null, // disasters.idの配列
        start_at_stt: searchTerms["start_at_stt"], // 開始日が指定日以降
        start_at_end: searchTerms["start_at_end"], // 開始日が指定日以前
        order: null, // デフォルト: complete_at
        sort: null, // デフォルト: desc, asc|desc
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        // レスポンスのデータを取得
        const data = response.data.disasters;
        const totalCount = response.data.total_count;
        const status = response.data;
        setDisasters(data);
        setTotalCount(totalCount);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //初回起動時の読み込み回避
  const isFirstRender = useRef(false);

  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false; // もう初回レンダーじゃないよ代入
    } else {
      getDisasterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const handleChangeTextField = (e) => {
    const newValue = e.target.value;
    setSearchTerms((prevValue) => ({
      ...prevValue,
      name: newValue,
    }));
  };

  const handleChangeCalender = (e, column) => {
    var year = e?.getFullYear();
    var month = (e?.getMonth() + 1).toString().padStart(2, "0");
    var day = e?.getDate().toString().padStart(2, "0");
    var formattedDateStr = year + "/" + month + "/" + day;
    console.log("eventlog", formattedDateStr);
    setSearchTerms((prevValue) => ({
      ...prevValue,
      [column]: formattedDateStr,
    }));
  };

  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (row, rowNum) => {
    setSelectedRow(row);
    setSelectedRowNum(rowNum);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //完了解除ポップオーバー管理
  const [confirmPopoverOpen, setConfirmPopoverOpen] = useState(false);
  const incompleteButtonClick = (e, disasterId) => {
    e.stopPropagation();
    //選択した災害のIDをセット
    setPutDisasterId(disasterId);
    setConfirmPopoverOpen(true);
  };
  const confirmPopupClose = (event) => {
    setConfirmPopoverOpen(false);
  };

  const [putDisasterId, setPutDisasterId] = useState();
  //完了処理
  const putInComplete = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${putDisasterId}`;
    // リクエストパラメータをオブジェクトとして定義
    const params = {
      id: putDisasterId,
      is_complete: false,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    // axiosを使用してGETリクエストを送信
    console.log("完了データ", params);
    isSuccess
      ? axios
          .put(apiUrl, params, headers)
          .then((response) => {
            // レスポンスのデータを取得
            const status = response.data;
            console.log("完了処理", response);
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            getDisasterData();
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //Excelダウンロード処理
  const getExcelFile = async (e, disasterId) => {
    e.stopPropagation();
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-excel/${disasterId}`;
    // リクエストパラメータをオブジェクトとして定義
    const params = {
      params: { is_meti: true },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };

    // axiosを使用してGETリクエストを送信
    console.log(
      "Excelファイル取得入力データ",
      "disaster_id=" + disasterId,
      params
    );
    axios
      .get(apiUrl, params)
      .then((response) => {
        // レスポンスのデータを取得
        const status = response.data;
        console.log("Excelファイル取得", response);
        // テナント情報をステートにセット
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  const ButtonValidate = (props) => {
    const { disasterId } = props;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Button
            variant="contained"
            onClick={(e) => incompleteButtonClick(e, disasterId)}
            sx={{
              marginLeft: "8px",
              pointerEvents: "auto",
              whiteSpace: "nowrap",
            }}
          >
            完了解除
          </Button>
        </Box>
      </Box>
    );
  };

  const { user } = useContext(TokenContext);
  const disasterRole = DisasterRole(user.m_organization.m_role_id)
  const disasterValidate = (disasterName) => {
    return (
      <Box
        sx={{
          borderRadius: "20px",
          padding: "8px",
          color: "white",
          fontWeight: "bold",
          width: "fit-content",
          backgroundColor:
            disasterName === "quake"
              ? "red"
              : disasterName === "w-r-s"
              ? "orange"
              : disasterName === "manual"
              ? "gray"
              : "black",
        }}
      >
        {disasterName === "quake"
          ? "地震"
          : disasterName === "w-r-s"
          ? "雨風雪"
          : disasterName === "manual"
          ? "手動"
          : disasterName === "alert"
          ? "警報"
          : "その他"}
      </Box>
    );
  };

  //登録ボタン選択行番号管理
  const [selectedRowNum, setSelectedRowNum] = useState("");

  //災害名管理
  const handleChangeDisaster = (inputValue, siteId) => {
    console.log("inputValue, rowNum", inputValue, siteId);
    const updatedArray = [...disasters]; // オリジナルの配列をコピー
    //sites配列内からidが当てはまる配列を探す
    const updatedItem = updatedArray.find((item) => item.id === siteId);
    if (updatedItem) {
      updatedItem.name = inputValue;
      setDisasters(updatedArray);
    } else {
      console.error(`sites with id ${siteId} not found`);
    }
  };

  //災害名登録ポップオーバー管理
  const [putNamePopoverOpen, setPutNamePopoverOpen] = useState(false);
  const putNameButtonClick = (e, rowNum) => {
    e.stopPropagation();
    setSelectedRowNum(rowNum);
    setPutNamePopoverOpen(true);
  };
  const putNamePopupClose = (event) => {
    setPutNamePopoverOpen(false);
  };

  //災害名更新
  const putDisasterName = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasters[selectedRowNum].id}`;
    const params = {
      id: disasters[selectedRowNum].id,
      name: disasters[selectedRowNum].name,
      is_complete: true,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("災害情報更新入力データ", params);
    isSuccess
      ? axios
          .put(apiUrl, params, headers)
          .then((response) => {
            const status = response.data;
            console.log("災害情報更新", response);
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            getDisasterData();
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //災害名更新ボタン
  const PutButton = (props) => {
    const { rowNum } = props;
    return (
      <IconButton
        color="primary"
        sx={{ pointerEvents: "auto" }}
        onClick={(e) => putNameButtonClick(e, rowNum)}
      >
        <SaveIcon />
      </IconButton>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        justifyContent: "center",
        borderRadius: 2,
        borderColor: "primary.main",
        boxShadow: 3,
        color: "outline.dark",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          gap: "16px",
          paddingTop: "32px",
          paddingRight: "40px",
          paddingLeft: "40px",
          paddingBottom: "32px",
          "@media screen and (max-width:900px)": {
            padding: "16px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            paddingLeft: "40px",
            "@media screen and (max-width:900px)": {
              padding: "0",
            },
          }}
        >
          検索条件
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:600px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Box sx={{ width: "248px" }}>災害名称</Box>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            onChange={(e) => handleChangeTextField(e)}
            sx={{
              width: "372px",
              "@media screen and (max-width:900px)": {
                width: "248px",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:600px)": {
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            },
          }}
        >
          <Box sx={{ padding: "8px 0 0 0", width: "248px" }}>
            災害発生年月日
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "372px",
              gap: "8px",
              "@media screen and (max-width:900px)": {
                width: "248px",
                flexDirection: "column",
              },
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ja} //ja追加
              dateFormats={{ monthAndYear: "yyyy/MM" }} //カレンダー左上の日付表示
              localeText={{
                previousMonth: "前月を表示", // < のツールチップ
                nextMonth: "次月を表示", // > のツールチップ
                cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
                okButtonLabel: "選択", // スマホ画面のOKボタン
                datePickerToolbarTitle: "日付選択",
              }}
            >
              <DatePicker
                label="start"
                inputFormat="yyyy-MM-dd" //選択済みの日付の表示
                mask="____年__月__日"
                toolbarFormat="yyyy年MM月dd日"
                textFieldProps={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    inputProps={{
                      inputMode: "numeric",
                      ...params.inputProps,
                      placeholder: "****年**月**日", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
                    }}
                  />
                )}
                onChange={(e) => handleChangeCalender(e, "start_at_stt")}
                slotProps={{
                  toolbar: {
                    toolbarFormat: "yyyy/MM/dd",
                  },
                  textField: { size: "small" },
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ja} //ja追加
              dateFormats={{ monthAndYear: "yyyy/MM" }} //カレンダー左上の日付表示
              localeText={{
                previousMonth: "前月を表示", // < のツールチップ
                nextMonth: "次月を表示", // > のツールチップ
                cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
                okButtonLabel: "選択", // スマホ画面のOKボタン
                datePickerToolbarTitle: "日付選択",
              }}
            >
              <DatePicker
                label="end"
                inputFormat="yyyy-MM-dd" //選択済みの日付の表示
                mask="____年__月__日"
                toolbarFormat="yyyy年MM月dd日"
                textFieldProps={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    inputProps={{
                      inputMode: "numeric",
                      ...params.inputProps,
                      placeholder: "****年**月**日", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
                    }}
                  />
                )}
                onChange={(e) => handleChangeCalender(e, "start_at_end")}
                slotProps={{
                  toolbar: {
                    toolbarFormat: "yyyy/MM/dd",
                  },
                  textField: { size: "small" },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "16px",
            "@media screen and (max-width:600px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Button
            variant="contained"
            onClick={getDisasterData}
            sx={{ width: "208px" }}
          >
            検索
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            gap: "16px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              paddingLeft: "40px",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索結果
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      position: column.position,
                      right: column.right,
                    }}
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      borderBottom: 2,
                      padding: "8px",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {disasters?.map((disaster, rowNum) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={disaster.id}
                    onClick={() => handleLocationCodeClick(disaster, rowNum)}
                    sx={{
                      pointerEvents: "none",
                      "@media screen and (max-width:900px)": {
                        cursor: "pointer",
                        pointerEvents: "auto",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            position: column.position,
                            right: column.right,
                            backgroundColor: column.backgroundColor,
                          }}
                          sx={{
                            padding: "8px",
                            "@media screen and (max-width:900px)": {
                              display:
                                column.isSmallScreen === true
                                  ? "table-cell"
                                  : "none",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "32px",
                            }}
                          >
                            {column.complete_user ? (
                              <>{disaster.complete_user.name}</>
                            ) : column.kind ? (
                              disasterValidate(disaster["kind"])
                            ) : column.textField ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <DisasterTextfield
                                  disasterData={disaster}
                                  id={disaster["id"]}
                                  changedValue={(value) =>
                                    handleChangeDisaster(value, disaster.id)
                                  }
                                  readOnly={!disasterRole.update}
                                />
                                {disasterRole.update ? (
                                  <PutButton rowNum={rowNum} />
                                ) : (
                                  <></>
                                )}
                              </Box>
                            ) : column.buttons ? (
                              disasterRole.update ? (
                                <ButtonValidate disasterId={disaster["id"]} />
                              ):(
                                <></>
                              )
                            ) : column.id === "create_at" ||
                              column.id === "update_at" ||
                              column.id === "complete_at" ? (
                              formatDate(disaster[column.id])
                            ) : (
                              <>{disaster[column["id"]]}</>
                            )}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              被害履歴詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {selectedRow &&
                columns.map((column) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                      <TableCell
                        align={column.align}
                        sx={{
                          color: "outline.dark",
                          fontWeight: "bold",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            WebkitWritingMode: "horizontal-tb",
                            height: "100%",
                          }}
                        >
                          {column.label}
                        </Box>
                      </TableCell>
                      <TableCell
                        align={column.align}
                        sx={{
                          writingMode: "horizontal-tb",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                          overflowWrap: "break-word",
                        }}
                      >
                        {column.complete_user ? (
                          <>{selectedRow.complete_user.name}</>
                        ) : column.kind ? (
                          disasterValidate(selectedRow["kind"])
                        ) : column.textField ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <DisasterTextfield
                              disasterData={selectedRow}
                              id={selectedRow?.["id"]}
                              changedValue={(value) =>
                                handleChangeDisaster(value, selectedRow?.id)
                              }
                              readOnly={!disasterRole.update}
                            />
                            {disasterRole.update ? (
                              <PutButton rowNum={selectedRowNum} />
                            ) : (
                              <></>
                            )}
                          </Box>
                        ) : column.buttons ? (
                          disasterRole.update ? (
                            <ButtonValidate disasterId={selectedRow?.["id"]} />
                          ):(
                            <></>
                          )
                        ) : column.id === "create_at" ||
                          column.id === "update_at" ||
                          column.id === "complete_at" ? (
                          formatDate(selectedRow?.[column.id])
                        ) : (
                          <>{selectedRow?.[column["id"]]}</>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Dialog>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalCount | 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
              margin: "0 !important",
              padding: "0 !important",
            },
          }}
        />
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={(e) => putInComplete(e)}
        title={"完了解除確認"}
        contents={"完了を解除してもよろしいですか？"}
      />
      <PopoverCustom
        open={putNamePopoverOpen}
        onClose={putNamePopupClose}
        yesNo={(e) => putDisasterName(e)}
        title={"災害名登録確認"}
        contents={"災害名を登録してもよろしいですか？"}
      />
    </Box>
  );
};
export default SearchResult;
