import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import { fetchSession } from "../../../../auth";

const columns = [
  {
    id: "create_at",
    label: "インポート日時",
    minWidth: "fit-content",
    isSmallScreen: true,
  },
  {
    id: "status",
    label: "ステータス",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: true,
  },
  {
    id: "complete_at",
    label: "完了日時",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "message_id",
    label: "メッセージ",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
  },
];

export default function ImportLog() {
  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //インポート履歴取得※
  const [importLogs, setImportLog] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const getImportLog = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/async_tasks`;
    const params = {
      params: {
        kind: "importUserMaster",
        stat: null,
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("インポート履歴入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.async_tasks;
        const totalCount = response.data.total_count;
        const status = response.data;
        console.log("インポート履歴取得", response);
        setImportLog(data);
        setTotalCount(totalCount);
        // スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getImportLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      padding: "8px",
                      fontWeight: "bold",
                      borderBottom: 2,
                      borderBottomColor: "outline.dark",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {importLogs?.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.dataId}
                    onClick={() => handleLocationCodeClick(row)}
                    sx={{
                      pointerEvents: "none",
                      "@media screen and (max-width:900px)": {
                        cursor: "pointer",
                        pointerEvents: "auto",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            padding: "8px",
                            "@media screen and (max-width:900px)": {
                              display:
                                column.isSmallScreen === true
                                  ? "table-cell"
                                  : "none",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "32px",
                            }}
                          >
                            <>{value}</>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              アカウント詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {selectedRow &&
                columns.map((column) => {
                  const value = selectedRow[column.id];
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                      <TableCell
                        align={column.align}
                        sx={{
                          color: "outline.dark",
                          fontWeight: "bold",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            WebkitWritingMode: "horizontal-tb",
                            height: "100%",
                          }}
                        >
                          {column.label}
                        </Box>
                      </TableCell>
                      <TableCell
                        align={column.align}
                        sx={{
                          writingMode: "horizontal-tb",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                          overflowWrap: "break-word",
                        }}
                      >
                        <>{value}</>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Dialog>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalCount | 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
              margin: "0 !important",
              padding: "0 !important",
            },
          }}
        />
        <SnackbarCustom
          data={snackBarstatus}
          openStatus={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
      </Paper>
    </>
  );
}
