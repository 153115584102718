import * as React from "react";
import {useContext, useState} from "react";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Box, TextField } from "@mui/material";
import MenuPopup from "./MenuPopup";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import SnackbarCustom from "../../components/SnackBarCustom";
import PopoverCustom from "../../components/PopoverCustom";
import {TokenContext, useToken} from "../../TokenContext";
import {formatDate, formatDateNotSymbol} from "../../utils/deteUtils";
import LoadingSpinner from "../../components/LoadingSpiner";
import { Save as SaveIcon } from "@mui/icons-material";
import { fetchSession } from "../../auth";
import {DashboardRole, DisasterExcelRole, DisasterRole} from "../../role";

const columns = [
  {
    id: "start_at",
    label: "災害対応日時（始）",
    minWidth: 80,
    isSmallScreen: true,
  },
  {
    id: "kind",
    label: "災害種別",
    minWidth: 80,
    isSmallScreen: true,
    kind: true,
  },
  {
    id: "name",
    label: "災害名",
    minWidth: 112,
    align: "left",
    isSmallScreen: true,
    textField: true,
  },
  {
    id: "disaster_status_count",
    label: "被災SS数",
    minWidth: 180,
    align: "left",
    format: (value) => {
      return value.toLocaleString("en-US");
    },
    operation: true,
    isSmallScreen: false,
  },
  {
    id: "disaster_excel_com",
    label: "社内用",
    minWidth: 40,
    align: "left",
    disaster_excel_com: true,
    isSmallScreen: false,
  },
  {
    id: "disaster_excel_meti",
    label: "METI用",
    minWidth: 40,
    align: "left",
    disaster_excel_meti: true,
    isSmallScreen: false,
  },
  {
    id: "excel_at",
    label: "Excel最終作成日",
    minWidth: 80,
    align: "left",
    format: (value) => value.toFixed(2),
    isSmallScreen: false,
  },
  {
    id: "buttonSpace",
    label: "",
    minWidth: 152,
    align: "left",
    format: (value) => value.toFixed(2),
    buttons: true,
    isSmallScreen: false,
  },
  {
    id: "create_user",
    label: "登録者",
    minWidth: 172,
    align: "left",
    format: (value) => value.toFixed(2),
    isSmallScreen: false,
  },
];

const DisasterTextfield = (props) => {
  const { disasterData, changedValue, readOnly } = props;
  const handleChange = (e) => {
    if (typeof changedValue === "function") {
      changedValue(e.target.value);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "8px" }}>
      <TextField
        size="small"
        variant="outlined"
        value={disasterData?.name}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ pointerEvents: "auto" }}
        InputProps={{
          readOnly: readOnly, // ここでreadOnlyを設定
        }}
      />
    </Box>
  );
};

export default function DisasterList() {
  const { user } = useContext(TokenContext);
  const dashboardRole = DashboardRole(user.m_organization.m_role_id)
  const disasterExcelRole = DisasterExcelRole(user.m_organization.m_role_id)
  const disasterRole = DisasterRole(user.m_organization.m_role_id)
  //一覧取得
  const [selectedRow, setSelectedRow] = useState([]);
  const [excelStatus, setExcelStatus] = useState([]);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (row, rowNum) => {
    setSelectedRow(row);
    setSelectedRowNum(rowNum);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };
  //検索結果ページネーション管理
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //一覧取得
  const [disasters, setDisasters] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const getDisasterData = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/dashboard`;
    // リクエストパラメータをオブジェクトとして定義
    const params = {
      params: {
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    // axiosを使用してGETリクエストを送信
    axios
      .get(apiUrl, params)
      .then((response) => {
        // レスポンスのデータを取得
        const data = response.data.disasters;
        const totalCount = response.data.total_count;
        // テナント情報をステートにセット
        setDisasters(data);
        setTotalCount(totalCount);
      })
      .catch((error) => {
        console.error("API呼び出しエラー1", error);
      });
  };

  useEffect(() => {
    getDisasterData();
    if (disasterExcelRole.read) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const intervalId = setInterval(() => {
        getCreateExcelTask();
      }, 10000); // 10000ミリ秒 = 10秒
      // クリーンアップ関数
      return () => clearInterval(intervalId);
    }
  }, [rowsPerPage, page]);

  // 更新処理
  const updateDisaster = async (disasterId) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/async_task`;
    const params = {
      kind: "createDisasterExcel",
      opt_id: disasterId,
    };

    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };

    console.log("更新入力データ", params);
    axios
      .post(apiUrl, params, headers)
      .then((response) => {
        const status = response.data;
        console.log("災害更新レスポンス:", response);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  const getCreateExcelTask = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/async_tasks`;
    const params = {
      params: {
        kind: "createDisasterExcel",
        stat: 0,
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.async_tasks;
        const status = response.data.status;
        if ("ng" !== status) {
          let excelStatus = [];
          for (let i = 0; i < data.length; i++) {
            excelStatus[data[i].opt_id] = data[i].stat;
          }
          setExcelStatus(excelStatus);
        }
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  const updateButtonClick = (e, disasterId) => {
    e.stopPropagation();
    updateDisaster(disasterId);
  };

  //完了処理
  const [putDisasterId, setPutDisasterId] = useState();
  const putComplete = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${putDisasterId}`;
    // リクエストパラメータをオブジェクトとして定義
    const params = {
      id: putDisasterId,
      is_complete: true,
    };

    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    // axiosを使用してGETリクエストを送信
    isSuccess
      ? axios
          .put(apiUrl, params, headers)
          .then((response) => {
            // レスポンスのデータを取得
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            handlePopoverClose();
            getDisasterData();
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  const ButtonVaridate = (props) => {
    const { disasterId } = props;
    return (
      <>
        <Box>
          {disasterExcelRole.update ? (
            <Button
              variant="outlined"
              onClick={(e) => updateButtonClick(e, disasterId)}
              sx={{ pointerEvents: "auto" }}
            >
              更新
            </Button>
          ) : (<></>)}
          {disasterRole.update ? (
            <Button
              variant="contained"
              onClick={(e) => completeButtonClick(e, disasterId)}
              sx={{ marginLeft: "8px", pointerEvents: "auto" }}
            >
              完了
            </Button>
          ) : (<></>)}
        </Box>
      </>
    );
  };

  //Excelダウンロード処理
  const getExcelFile = async (e, disasterId, isMeti, excelAt, disasterName) => {
    e.stopPropagation();
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-excel/${disasterId}`;
    // リクエストパラメータをオブジェクトとして定義
    const params = {
      params: { is_meti: isMeti },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },

      responseType: "blob", // blob形式でレスポンスを受け取る
    };

    // axiosを使用してGETリクエストを送信
    axios
      .get(apiUrl, params)
      .then((response) => {
        // レスポンスのデータを取得
        const status = response.data;
        // バイナリデータとしてレスポンスを取得
        // バイナリデータからBlob URLを生成
        if (response.data.type === "application/json") {
          // setSnackBarstatus(status);
        } else {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          if (isMeti) {
            a.download = `${disasterName}_METI報告_${formatDateNotSymbol(excelAt)}.xlsx`; // ダウンロードするファイル名
          } else {
            a.download = `${disasterName}_被害報告社内用_${formatDateNotSymbol(excelAt)}.xlsx`; // ダウンロードするファイル名
          }

          document.body.appendChild(a); // 一時的にリンクをDOMに追加
          a.click(); // リンクをクリックしてダウンロードを開始
          a.remove(); // リンクを削除
          window.URL.revokeObjectURL(url); // ObjectURLを解放
          // テナント情報をステートにセット
          // setSnackBarstatus(status);
          // setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  const OperationValidate = (props) => {
    const { disasterId, value, excelExists, disasterName } = props;
    return (
      <>
        <Box sx={{ minWidth: "32px" }}>{value}</Box>
        <Box sx={{ minWidth: "128px", display: "flex", gap: "8px", alignItems: "center" }}>
          <MenuPopup disasterId={disasterId} />
        </Box>
      </>
    );
  };
  const ExcelComValidate = (props) => {
    const { disasterId, value, excelExists, disasterName } = props;
    return (
      <>
        <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
          {excelStatus[disasterId] === 0 ? (
            <LoadingSpinner />
          ) : excelExists ? (
            <IconButton
              sx={{ pointerEvents: "auto" }}
              onClick={(e) => getExcelFile(e, disasterId, false, excelExists, disasterName)}
            >
              <DownloadIcon color="primary" />
            </IconButton>
          ) : (
            ""
          )}
        </Box>
      </>
    );
  };
  const ExcelMetiValidate = (props) => {
    const { disasterId, value, excelExists, disasterName } = props;
    return (
      <>
        <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
          {excelStatus[disasterId] === 0 ? (
            <LoadingSpinner />
          ) : excelExists ? (
            <IconButton
              sx={{ pointerEvents: "auto" }}
              onClick={(e) => getExcelFile(e, disasterId, true, excelExists, disasterName)}
            >
              <DownloadIcon color="secondary" />
            </IconButton>
          ) : (
            ""
          )}
        </Box>
      </>
    );
  };
  const disasterValidate = (disasterName) => {
    return (
      <Box
        sx={{
          borderRadius: "20px",
          padding: "8px",
          color: "white",
          fontWeight: "bold",
          width: "fit-content",
          backgroundColor:
            disasterName === "quake"
              ? "red"
              : disasterName === "w-r-s"
              ? "orange"
              : disasterName === "manual"
              ? "gray"
              : "black",
        }}
      >
        {disasterName === "quake"
          ? "地震"
          : disasterName === "w-r-s"
          ? "雨風雪"
          : disasterName === "manual"
          ? "手動"
          : disasterName === "alert"
          ? "警報"
          : "その他"}
      </Box>
    );
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //完了確認ポップオーバー管理
  const [confirmPopoverOpen, setConfirmPopoverOpen] = useState(false);
  const completeButtonClick = (e, disasterId) => {
    e.stopPropagation();
    setPutDisasterId(disasterId);
    setConfirmPopoverOpen(true);
  };
  const confirmPopupClose = (event) => {
    setConfirmPopoverOpen(false);
  };

  //登録ボタン選択行番号管理
  const [selectedRowNum, setSelectedRowNum] = useState("");

  //災害名管理
  const handleChangeDisaster = (inputValue, siteId) => {
    const updatedArray = [...disasters]; // オリジナルの配列をコピー
    //sites配列内からidが当てはまる配列を探す
    const updatedItem = updatedArray.find((item) => item.id === siteId);
    if (updatedItem) {
      updatedItem.name = inputValue;
      setDisasters(updatedArray);
    } else {
      console.error(`sites with id ${siteId} not found`);
    }
  };

  //災害名登録ポップオーバー管理
  const [putNamePopoverOpen, setPutNamePopoverOpen] = useState(false);
  const putNameButtonClick = (e, rowNum) => {
    e.stopPropagation();
    setSelectedRowNum(rowNum);
    setPutNamePopoverOpen(true);
  };
  const putNamePopupClose = (event) => {
    setPutNamePopoverOpen(false);
  };

  //災害名更新
  const putDisasterName = async (isSuccess) => {
    const tokenInfo = await fetchSession();

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasters[selectedRowNum].id}`;
    const params = {
      id: disasters[selectedRowNum].id,
      name: disasters[selectedRowNum].name,
      is_complete: false,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    isSuccess
      ? axios
          .put(apiUrl, params, headers)
          .then((response) => {
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            getDisasterData();
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //災害名更新ボタン
  const PutButton = (props) => {
    const { rowNum } = props;
    return (
      <IconButton
        color="primary"
        sx={{ pointerEvents: "auto" }}
        onClick={(e) => putNameButtonClick(e, rowNum)}
      >
        <SaveIcon />
      </IconButton>
    );
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {/* <Box>Dashboard2
        <Button onClick={consoleCheck}>コンソール確認</Button>
      </Box> */}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    color: "outline.dark",
                    fontWeight: "bold",
                    borderBottom: 2,
                    padding: "8px",
                    "@media screen and (max-width:900px)": {
                      display:
                        column.isSmallScreen === true ? "table-cell" : "none",
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {disasters?.map((disaster, rowNum) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={disaster.id}
                  onClick={() => handleLocationCodeClick(disaster, rowNum)}
                  sx={{
                    pointerEvents: "none",
                    "@media screen and (max-width:900px)": {
                      cursor: "pointer",
                      pointerEvents: "auto",
                    },
                  }}
                >
                  {columns.map((column, index) => {
                    return (
                      <TableCell
                        key={disaster.id + column.id}
                        align={column.align}
                        sx={{
                          padding: "8px",
                          "@media screen and (max-width:900px)": {
                            display:
                              column.isSmallScreen === true
                                ? "table-cell"
                                : "none",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "24px",
                          }}
                        >
                          {column.kind ? (
                            disasterValidate(disaster.kind)
                          ) : column.buttons ? (
                            <ButtonVaridate disasterId={disaster["id"]} />
                          ) : column.operation ? (
                            <OperationValidate
                              disasterId={disaster["id"]}
                              value={disaster[column.id]}
                              excelExists={disaster["excel_at"]}
                              disasterName={disaster["name"]}
                            />
                          ) : column.disaster_excel_com ? (
                            disasterExcelRole.read ? (
                              <ExcelComValidate
                                disasterId={disaster["id"]}
                                value={disaster[column.id]}
                                excelExists={disaster["excel_at"]}
                                disasterName={disaster["name"]}
                              /> ) : (<></>)
                          ) : column.disaster_excel_meti ? (
                            disasterExcelRole.read ? (
                              <ExcelMetiValidate
                                disasterId={disaster["id"]}
                                value={disaster[column.id]}
                                excelExists={disaster["excel_at"]}
                                disasterName={disaster["name"]}
                              /> ) : (<></>)
                          ) : column.textField ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <DisasterTextfield
                                disasterData={disaster}
                                id={disaster["id"]}
                                changedValue={(value) =>
                                  handleChangeDisaster(value, disaster.id)
                                }
                                readOnly={!disasterRole.update}
                              />
                              {disasterRole.update ? (
                                <PutButton rowNum={rowNum} />
                              ) : (
                                <></>
                              )}
                            </Box>
                          ) : column.id === "start_at" ||
                            column.id === "excel_at" ? (
                            formatDate(disaster[column.id])
                          ) : column.id === "create_user" ? (
                            <>{disaster["create_user"]?.["name"]}</>
                          ) : (
                            <>{disaster[column.id]}</>
                          )}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
        <Box
          sx={{
            display: "flex",
            height: "56px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #CCC",
          }}
        >
          <Box
            sx={{
              paddingLeft: "8px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            対応中災害詳細
          </Box>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
          <TableBody>
            {selectedRow &&
              columns
                .slice(0, 5)
                .concat(columns.slice(6, 8))
                .map((column) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                      <TableCell
                        align={column.align}
                        sx={{
                          color: "outline.dark",
                          fontWeight: "bold",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            WebkitWritingMode: "horizontal-tb",
                            height: "100%",
                          }}
                        >
                          {column.label}
                        </Box>
                      </TableCell>
                      <TableCell
                        align={column.align}
                        sx={{
                          writingMode: "horizontal-tb",
                          boxSizing: "border-box",
                          width: "calc((100% - 64px) / 2)",
                          overflowWrap: "break-word",
                        }}
                      >
                        {column.kind ? (
                          disasterValidate(selectedRow["kind"]) // column.kindが存在する場合
                        ) : column.operation ? (
                          <Box>
                            <>{selectedRow[column.id]}</>
                          </Box>
                        ) : column.textField ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <DisasterTextfield
                              disasterData={selectedRow}
                              id={selectedRow?.["id"]}
                              changedValue={(value) =>
                                handleChangeDisaster(value, selectedRow.id)
                              }
                              readOnly={!disasterRole.update}
                            />
                            {disasterRole.update ? (
                              <PutButton rowNum={selectedRowNum} />
                            ) : (
                              <></>
                            )}
                          </Box>
                        ) : column.id === "start_at" ||
                          column.id === "excel_at" ? (
                          formatDate(selectedRow[column.id])
                        ) : column.id === "create_user" ? (
                          <>{selectedRow["create_user"]?.["name"]}</>
                        ) : (
                          <>{selectedRow[column.id]}</>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
        <Box
          sx={{
            margin: "16px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <MenuPopup disasterId={selectedRow["id"]} />
          <ButtonVaridate disasterId={selectedRow["id"]} />
        </Box>
      </Dialog>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount | 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
            margin: "0 !important",
            padding: "0 !important",
          },
        }}
      />
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={(e) => putComplete(e)}
        title={"完了確認"}
        contents={"完了してもよろしいですか？"}
      />
      <PopoverCustom
        open={putNamePopoverOpen}
        onClose={putNamePopupClose}
        yesNo={(e) => putDisasterName(e)}
        title={"災害名登録確認"}
        contents={"災害名を登録してもよろしいですか？"}
      />
    </Paper>
  );
}
